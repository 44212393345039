<template>
  <div class="main">
    <common-top
      title="擂台"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <dept-choose-com
      :defaultEndDate="endDate"
      :defaultStartDate="startDate"
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="isShowDept"
      @cancel="showModal = false"
      :isShowTime="true"
    ></dept-choose-com>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div style="position: relative" v-show="showList && isUserPm">
      <desc-com
        :title="descTitle"
        :startDate="startDate"
        :endDate="endDate"
        :isFilter="true"
        :sortList="sortList"
        @sortData="sortData"
      ></desc-com>

      <leitai-activity-com
        @chooseActivity="chooseActivity"
      ></leitai-activity-com>

      <list-com
        @chooseListItem="chooseListItem"
        :columns="columns"
        :title="title"
        :showList="dataList"
        :showLoad="showLoad"
      ></list-com>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import { wxConPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptChooseCom.vue";
import descCom from "../../components/descComAndSort.vue";
import noPassCom from "../../components/noPassCom.vue";
import leitaiActivityCom from "../../components/leitaiActivityCom.vue";

export default {
  name: "deptLeitai",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    descCom,
    noPassCom,
    leitaiActivityCom,
  },

  data() {
    return {
      filterNameArr: [],
      activityId: "",

      descTitle: "", // 描述说明
      isShowSearch: false, // 是否显示搜索框
      isShowDept: false, // 超管部门选择
      isUserPm: true, // 是否有权限

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级
      startDate: "2022-01-01",
      endDate: "2022-02-20",

      detailInfo: {}, // 单位详情
      title: "单位",
      // columns: ["平台总活跃度", "人均活跃度", "阅读活跃度", "环比增长"],
      columns: [
        { name: "得分", value: "score" },
        { name: "正确率排名", value: "正确率排名" },
        { name: "答题次数", value: "count" },
        { name: "答题时长(时)", value: "答题时长" },
      ],
      sortList: [
        // 默认降序 desc  可选升序 asc
        // { name: "得分", value: "score", order: "desc" },
        // { name: "正确率排名", value: "correctRate", order: "asc" },
        // { name: "答题次数", value: "count", order: "desc" },
        // { name: "答题时长", value: "timeMs", order: "desc" },

        { name: "得分", value: "score", order: "desc" },
        { name: "正确率排名", value: "正确率排名", order: "aes" },
        { name: "答题次数", value: "count", order: "desc" },
      ],
      showList: true,
      deptData: [],
      dataList: [],
      ratioData: [],

      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      deptInfo: {},
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      dataTimer: null,
      radioTimer: null,
    };
  },
  methods: {
    async chooseActivity(activityId) {
      this.activityId = activityId;
      const userPm = getUserPm();
      if (userPm) {
        this.getDeptStatus();
      }
    },
    // 选择部门
    chooseDept(emitData) {
      if (emitData.type == "单位") {
        this.deptLevel = 2;
      } else {
        this.deptLevel = 3;
      }
      this.deptIdArr = emitData.deptIdArr;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;

      this.showModal = false;
      this.getDeptStatus();
    },
    clearTimmerFun() {
      if (this.dataTimer) {
        clearTimeout(this.dataTimer);
      }
      if (this.radioTimer) {
        clearTimeout(this.radioTimer);
      }
    },
    async getDeptStatus() {
      this.clearTimmerFun();

      try {
        if (!this.activityId) {
          return;
        }
        this.descTitle = "统计中..";
        this.showLoad = true;
        this.showTaskList = false;

        let _query = {
          startDate: this.startDate,
          endDate: this.endDate,
          id: this.activityId,
        };
        if (this.deptIdArr.length > 0) {
          _query["deptIdArr"] = this.deptIdArr;
          _query["deptLevel"] = this.deptLevel;
        }
        const ret = await wxConPost(
          "/services/gttt-wxstats-mgt/leitai/tjExamInfo",
          _query
        );

        if (ret.err) {
          this.showLoad = false;
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }

        let deptData = _.get(ret, ["ret"], []);
        if (deptData && deptData.length <= 0) {
          this.dataList = [];
          this.showLoad = false;
          // this.$Message.info("数据处理中，请稍后");
        } else {
          this.clearTimmerFun();
          this.descTitle = "";
          this.showLoad = false;
          this.filterNameArr = ret.filterNameArr;
          this.dealData(deptData);
        }
      } catch (error) {
        console.log("----", error);
        this.showLoad = false;
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },

    // 处理数据
    dealData(data) {
      let _tmpdataList = [];

      let fillStatus = false;
      // 增加过滤数据 非领导班子 站段4级管理员
      if (!_.isEmpty(this.filterNameArr)) {
        fillStatus = true;
      }

      for (const iterator of data) {
        if (iterator["deptName"]) {
          if (fillStatus) {
            if (this.filterNameArr.indexOf(iterator["deptName"]) == -1) {
              continue;
            }
          }

          let correctRate = 0;
          if (iterator.paperScore) {
            correctRate = (iterator.score / iterator.paperScore) * 100;
          }

          // count: 5
          // deptId: 39
          // deptName: "衡阳车务段"
          // paperScore: 50
          // score: 10
          // timeMs: 719687
          // userCount: 3173
          // userStatusCount: 3101
          _tmpdataList.push(
            Object.assign(iterator, {
              count: iterator["count"] || 0,
              score: iterator["score"] || 0,
              timeMs: iterator["timeMs"] || 0,
              答题时长: iterator["timeMs"]
                ? (iterator["timeMs"] / 3600).toFixed(2)
                : 0,
              correctRate,
              正确率: `${correctRate.toFixed(2)}%`,
            })
          );
        }
      }
      const _tmpAllDataList = _.reverse(
        _.sortBy(_tmpdataList, ["correctRate"])
      );

      let _dataList = [];
      for (const [index, iterator] of _tmpAllDataList.entries()) {
        if (iterator.deptName) {
          _dataList.push(
            Object.assign(iterator, {
              正确率排名: index + 1,
            })
          );
        }
      }
      this.dataList = _.reverse(_.sortBy(_dataList, ["score"]));
    },

    // 显示详情
    chooseListItem(item) {
      this.deptUserOption = null;
      this.detailInfo = item;
    },
    chooseItem(data) {
      this.showTaskList = false;
      this.deptData = data.data.retData;
    },

    async getDeptList() {
      try {
        const ret = await wxConPost(
          "/services/gttt-wxstats-mgt/user/getDeptList",
          {}
        );
        this.deptInfo = _.get(ret, ["ret"], {});
      } catch (error) {
        console.log("----", error);
      }
    },

    // 数据排序
    sortData(data) {
      console.log("----", data);
      if (data && data.value) {
        if (data.order == "aes") {
          // 降序
          this.dataList = _.sortBy(this.dataList, [data.value]);
        } else {
          // 升序
          this.dataList = _.reverse(_.sortBy(this.dataList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
  },
  destroyed() {
    this.clearTimmerFun();
  },
  async created() {
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    const userPm = getUserPm();
    console.log("userPm----", userPm);
    if (userPm) {
      // this.getDeptList();
      this.isShowSearch = true;
      if (userPm.pmValue && userPm.pmSuper) {
        this.isShowDept = true;
      }
      // this.getDeptStatus();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
